<template>
  <div class="option-manage">
    <div class="title">
      <div class="select">
        <span>页面排序方式：</span>
        <span>{{formValidate.voteItemSeqList[formValidate.voteItemSeq -1].dictValue}}</span>
      </div>
    </div>
    <div class="content">
      <Table :columns="columns" :data="formValidate.voteItemRos">
      </Table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["formValidate", "ruleValidate"],
  data() {
    return {
      tableData: [
        {
          number: "",
          title: "",
          detail: "",
          imgSrc: "",
        },
      ],
      columns: [
        {
          title: "序号",
          width: 120,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span",params.row.seq),
            ]);
          },
        },
        {
          title: "文本内容",
          width: 500,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("div",`选项标题：${ params.row.title || '无'}`),
              h("div", `详情描述：${params.row.subtitle || '无'}`),
            ]);
          },
        },
        {
          title: "封面预览（选填）",
          // slot: "cover",
          width: 200,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("img", {
                attrs: {
                  width: 100,
                  height: 100,
                  attr: "请选择封面",
                  src: this.formValidate.voteItemRos[params.index].coverImg,
                },
                style: {
                  width: "100px",
                  height: "100px",
                  border: "1px solid #ccc",
                  display: this.formValidate.voteItemRos[params.index].coverImg ? '' : 'none'
                },
              }),
              h("span",{
                style: {
                  display: this.formValidate.voteItemRos[params.index].coverImg ? 'none' : ''
                }
              },'无')
            ]);
          },
        },
      ],
      // 图片、视频上传参数
      uploadImageData: {},
      uploadVideoData: {},
      //图片、视频名称
      imageName: "",
      videoName: "",
      //图片、视频路径
      imagePath: "",
      videoPath: "",
      //oss上传地址
      actionImage: "",
      actionVideo: '',
    };
  },
  methods: {
    backStep() {
      this.$emit("nextStep", -1);
    },
    nextStep() {
      this.$emit("nextStep", 1);
    },
  },
};
</script>
    
<style scoped lang='less'>
.option-manage {
  width: 100%;
  .title {
    display: flex;
    align-items: center;
    .desc {
      color: #ccc;
    }
  }
  .content {
    margin-top: 20px;
    /deep/.ivu-table {
      th {
        background-color: transparent;
      }
    }
    /deep/table {
      width: 100% !important;
    }
    /deep/.ivu-table {
      overflow: auto;
      height: calc(100vh - 380px);
    }
    .ivu-table-wrapper {
      height: auto;
    }
  }
  .ivu-table-cell-slot {
    .ivu-btn {
      margin: 10px 0;
    }
  }
  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    button {
      margin-left: 30px;
    }
  }
}
</style>
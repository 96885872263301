<template>
  <div class="baseInto" ref="info">
    <!-- 右边表单区块 -->
    <Form
      ref="formValidate"
      :model="formValidate"
      :rules="ruleValidate"
      :label-width="100"
      :label-colon="true"
    >
      <FormItem label="信息名称">
        <p>{{formValidate.title || '空'}}</p>
      </FormItem>
      <FormItem label="信息来源">
        <p>{{formValidate.source || '空'}}</p>
      </FormItem>
      <FormItem label="信息类型" class="form-item">
        <p>{{formValidate.subtype || '空'}}</p>
      </FormItem>
      <FormItem label="联系电话" class="form-item">
        <p>{{formValidate.appointmentPhone || '空'}}</p>
      </FormItem>
      <FormItem label="公布时间" prop="startTime" class="form-item">
        <p>{{formValidate.startTime || '空'}}</p>
        
      </FormItem>
      <FormItem label="截至时间" prop="endTime" class="form-item">
        <p>{{formValidate.endTime || '空'}}</p>
      </FormItem>
      <FormItem label="发布范围" class="form-item" style="width: 100%">
         <Collapse>
        <Panel name="1">
            查看发布范围
            <p slot="content">{{formValidate.city !='' ? formValidate.city : '无'}}</p>
        </Panel>
        </Collapse>
      </FormItem>
      
      <!-- <iframe name="ueditor" id="ueditor" src="/ueditor/index.html?titlename=详情描述"
          style="width:100%;height:500px;border:0px;" @load="loadUeditor"></iframe> -->
          <iframe
                name="ueditor"
                id="ueditor"
                src="/mobileeditor/#/index?titlename=详情描述"
                style="width: 100%; height: 500px; border: 0px"
                @load="loadUeditor"
              ></iframe>
    </Form>
  </div>
</template>

<script>
export default {
  props: ["formValidate", "ruleValidate"],
  data() {
    return {

    };
  },
  created(){
    sessionStorage.setItem('NODEENV',process.env.NODE_ENV)
  },
  methods: {
    loadUeditor() {
      
      if(this.formValidate&&this.formValidate.voteId!=null&&this.formValidate.voteId!=""){
        var v_Obj=document.getElementById("ueditor").contentWindow;//获取iframe对象
       console.log(v_Obj.previewState);
      v_Obj.setContent(this.formValidate.content);//写入编辑器富文本内容
       v_Obj.setPreview(true);
      }else{
       setTimeout(this.loadUeditor,1000);
      }
    },
    //下一步
    nextStep() {
      this.$emit("nextStep", 1);
    }
  },
};
</script>
    
<style scoped lang='less'>
.baseInto {
  height: 100%;
  width: 100%
}
.ivu-form {
  .form-item {
    width: 50%;
    display: inline-block;
  }
  /deep/.ivu-select-dropdown {
    z-index: 99999;
  }
  .ivu-date-picker {
    width: 100%;
  }
  .nextStep {
    text-align: center;
  }
  .time-interval {
    width: 1%;
    margin: 0 1%;
  }
  /deep/.edui-editor {
    z-index: 1 !important;
  }
}
</style>
<template>
  <div class="high-setting">
    <Form
      ref="formValidate"
      :model="formValidate"
      :label-width="130"
      :label-colon="true"
      :rules="ruleValidate"
      disabled
    >
      <FormItem label="每天投票起始">
        <FormItem style="float: left">
        <div>
          <span>每天</span>
          <span style="margin: 0 6px;color: #333;font-size: 18px">{{formValidate.voteStartTime}}</span>
          <span>之前不能投票</span>
        </div>
         </FormItem>
         <FormItem style="margin-left: 30px;float: left">
        <div>
          <span>每天</span>
          <span style="margin: 0 6px;color: #333;font-size: 18px">{{formValidate.voteEndTime}}</span>
          <span>之后不能投票</span>
        </div>
         </FormItem>
      </FormItem>
      <FormItem label="评论">
        <p>{{ formValidate.openDiscussList[formValidate.openDiscuss - 1].dictValue }}</p>
      </FormItem>
      <FormItem label="实名认证">
        <p>{{ formValidate.realNameAuthList[formValidate.realNameAuth - 1].dictValue }}</p>
      </FormItem>
      <FormItem label="是否推荐">
        <p>{{ formValidate.recommend === '1' ? '是' : '否' }}</p>
      </FormItem>
      <FormItem label="推送方式">
        <p>{{formValidate.pushChannel===true?'小程序订阅通知':'未推送'}}</p>
      </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
  props: ["formValidate", "ruleValidate"],
  data() {
    return {
      formData: {},
    };
  },
  methods: {
    backStep() {
      this.$emit("nextStep", -1);
    },
    reject() {
       this.$emit("reject");
    },
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style scoped lang='less'>
.high-setting {
  width: 100%;
  .ivu-date-picker {
    margin: 0 6px;
  }
  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    button {
      margin-left: 20px;
    }
  }
}
</style>
